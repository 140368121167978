html,
body {
    padding: 0 !important;
    margin: 0 !important;
    font-family: "InterRegular";
    overflow-x: hidden;
    scroll-behavior: smooth;
    background: linear-gradient(107.54deg, #f1f3f6 0%, #deebff 100%);
    height: 100%;
}
* {
    box-sizing: border-box;
}

a {
    color: inherit;
    text-decoration: none;
    font-family: "InterRegular";
}

@font-face {
    font-family: "InterLight";
    src: local("Inter-Light"), url("/src/assets/fonts/Inter-Light.ttf") format("trueType");
}
@font-face {
    font-family: "InterRegular";
    src: local("Inter-Regular"), url("/src/assets/fonts/Inter-Regular.ttf") format("trueType");
}
@font-face {
    font-family: "InterMedium";
    src: local("Inter-Medium"), url("/src/assets/fonts/Inter-Medium.ttf") format("trueType");
}
@font-face {
    font-family: "InterSemiBold";
    src: local("Inter-SemiBold"), url("/src/assets/fonts/Inter-SemiBold.ttf") format("trueType");
}
@font-face {
    font-family: "InterBold";
    src: local("Inter-Bold"), url("/src/assets/fonts/Inter-Bold.ttf") format("trueType");
}
::-webkit-scrollbar {
    width: 8px;
    height: 6px;
    z-index: 10;
}

::-webkit-scrollbar-track,
::-webkit-scrollbar-thumb {
    border-radius: 14px;
    z-index: 10;
}

::-webkit-scrollbar-track {
    background: transparent;
    /* margin: 40px; */
    z-index: 10;
}

::-webkit-scrollbar-thumb {
    background: rgb(190, 190, 190);
    z-index: 10;
}

::-webkit-scrollbar-thumb:hover {
    background: rgb(116, 116, 116);
    z-index: 10;
}

.toast-container {
    transition: none !important;
}

.toast-item {
    animation: none !important;
}

.css-78trlr-MuiButtonBase-root-MuiIconButton-root:hover {
    height: 35px;
    width: 35px;
}

.MuiPaper-root.MuiMenu-paper.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper.css-bu790k-MuiPaper-root-MuiMenu-paper-MuiPopover-paper {
    left: 641;
}

::-ms-reveal {
    display: none;
}

.css-mbm8ol-MuiInputBase-root-MuiOutlinedInput-root {
    padding: 10px 0px !important;
}

.grecaptcha-badge {
    display: none !important;
}
