.react-tel-input .selected-flag .arrow {
  display: none;
}
.react-tel-input .selected-flag {
  width: 60px;
  height: 100%;
  border-radius: 5px;
  z-index: 99;
}
.react-tel-input .flag-dropdown.open .selected-flag {
  border-radius: 5px;
}
.react-tel-input .flag-dropdown.open {
  border-radius: 5px;
}
.react-tel-input .selected-flag:hover,
.react-tel-input .selected-flag:focus {
  background-color: transparent;
  border: none;
  border-radius: 5px;
}
.react-tel-input .flag-dropdown {
  border-right: "1px solid #CACACA";
  border-top: none;
  border-bottom: none;
  border-left: none;
  border-top-left-radius: 5px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 0px;
}
.react-tel-input .form-control {
  padding: 0 0 0 4.5rem;
  border: none;
}
.react-tel-input .country-list {
  height: 10rem !important;
  border: none;
}

/* Placeholder */
.react-tel-input ::placeholder {
  color: #a4a4a4 !important;
  font-size: 14px;
}

/* .react-tel-input .country-list .country:hover {
  background: rgba(243, 250, 255, 1) !important;
}
.react-tel-input .country-list .country.highlight {
  background: rgba(243, 250, 255, 1) !important;
} */

.country-list::-webkit-scrollbar {
  width: 10px !important;
  height: 30px !important;
  margin: 0 !important;
}

.country-list::-webkit-scrollbar-thumb {
  border-radius: 20px !important;
  border: solid 1px transparent !important;
}
*/ .country-list::-webkit-scrollbar-corner {
  background-color: grey;
}
